import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import LogoPreview from 'components/LogoPreview';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "categorisation",
      "style": {
        "position": "relative"
      }
    }}>{`Categorisation`}</h2>
    <p>{`A brand may have multiple categories of logos (e.g. brand, subscriptions, ...).
These logos are brand-specific, i.e. not every brand will have every variation
of a logo.`}</p>
    <p>{`However, all brands have a `}<inlineCode parentName="p">{`Main`}</inlineCode>{` category, that currently contains three logos:`}</p>
    <ul>
      <li parentName="ul">{`Brand Main`}</li>
      <li parentName="ul">{`Brand Square Main`}</li>
      <li parentName="ul">{`Brand Plus Main`}</li>
    </ul>
    <p>{`These logo's will be provided for each brand, and will always be a selection
made from their variations.`}</p>
    <h2 {...{
      "id": "naming",
      "style": {
        "position": "relative"
      }
    }}>{`Naming`}</h2>
    <p>{`Naming a logo is hard. However, a clear name is needed to improve the
communication when it's about a particular logo.`}</p>
    <p>{`To avoid possible conflicts with new logos popping up, we have chosen a very
pragmatic approach when it comes to naming: we just explicitly state everything
that is included in the logo.`}</p>
    <p>{`This lead us to the following structure:`}</p>
    <ul>
      <li parentName="ul">{`First part: area the logo is used in (e.g. `}<inlineCode parentName="li">{`brand`}</inlineCode>{` if it's general, `}<inlineCode parentName="li">{`abo`}</inlineCode>{` if
it's related to subscriptions, ...)`}</li>
      <li parentName="ul">{`After that, there's the possibility of the logo being contained within a shape
(e.g. `}<inlineCode parentName="li">{`square`}</inlineCode>{` or `}<inlineCode parentName="li">{`rectangle`}</inlineCode>{`)`}</li>
      <li parentName="ul">{`Third element is what is present in the logo. I.e. the `}<inlineCode parentName="li">{`icon`}</inlineCode>{`, the `}<inlineCode parentName="li">{`name`}</inlineCode>{`, ..`}</li>
      <li parentName="ul">{`If more than 1 element is present, we also include the orientation they have
(`}<inlineCode parentName="li">{`vertical`}</inlineCode>{`, `}<inlineCode parentName="li">{`horizontal`}</inlineCode>{`, `}<inlineCode parentName="li">{`nested`}</inlineCode>{`, ...)`}</li>
      <li parentName="ul">{`Lastly, if the logo is not the brand's primary color, we also include the main
color`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Example:`}</strong>{` `}<strong parentName="p">{`Brand Square Icon Name Horizontal White`}</strong>{` This would be a
general logo that is contained in a square. In it, the icon and name are
present and are arranged in a horizontal manner. The main color is not the
brand's primary color, but instead it is white.`}</p>
    </blockquote>
    <h2 {...{
      "id": "versioning",
      "style": {
        "position": "relative"
      }
    }}>{`Versioning`}</h2>
    <p>{`To prevent an update to a logo breaking existing layouts, logos are versioned.
If a logo were to change substantially (either by its dimensions changing, or by
it being significantly different), a new version is to be released.`}</p>
    <p>{`Versioning is present in the url (e.g. `}<inlineCode parentName="p">{`/logos/brand/v1`}</inlineCode>{`). The logos on this
page will always be the latest version.`}</p>
    <p>{`However, next to a versioned url, there's also a `}<inlineCode parentName="p">{`/latest/`}</inlineCode>{` version, that will
always contain the latest version. Be aware that using this URL directly might
break your layout, and thus should always be used with great caution.`}</p>
    <hr></hr>
    <LogoPreview mdxType="LogoPreview" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      